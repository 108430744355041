<template>
    <!-- there is no any element in this component -->
</template>

<script>

export default{
    props:{
      millisecondsMouseMoveEvent:Number
    },
    data(){
        return{
            youtubePlayer: null,
            fakeEventInterval: null,
        }
    },
    created(){
        this.loadYouTubeAPI();
    },
    beforeDestroy() {
      this.destroyInterval();
    },
    methods:{
      loadYouTubeAPI() {
        if (window.YT && window.YT.Player) {
          this.initializeYouTubePlayer();
        } else {
          const tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          tag.async = true;
          tag.defer = true;
          document.head.appendChild(tag);
          window.onYouTubeIframeAPIReady = this.initializeYouTubePlayer;
        }
      },
      initializeYouTubePlayer() {
        this.youtubePlayer = new YT.Player("youtubePlayer", {
          events: {
            "onStateChange": this.onPlayerStateChange
          }
        });
      },
      onPlayerStateChange(event) {
        this.destroyInterval()
        if (event.data === YT.PlayerState.PLAYING){                       
          this.fakeEventInterval = setInterval(()=>{
              window.dispatchEvent(new Event("mousemove"));
          }, this.millisecondsMouseMoveEvent);
        }
      },
      destroyInterval(){
        if (this.fakeEventInterval) {
            clearInterval(this.fakeEventInterval);
            this.fakeEventInterval = null;
        }
      }
    }
};
</script>