<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2"></div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="" footer-classes="pb-2">
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-md-12 text-left">
                <h2 class="mb-0">My Courses</h2>
              </div>
              <div class="col-md-12 text-right">
                <base-button
            name="Pay Course"
            type="primary"
            @click.prevent="purchaseModal()"
          >
            Purchase New Course

          </base-button>
              </div>
            </div>
          </template>
          <div>
        <div class="row" v-if="folderProgress.length > 0">
          <div class="col-xl-12">
            <div class="">
              <div class="row">
                <div
                  :class="folderProgress.length== 3 ? 'col-md-4' : folderProgress.length== 2 ? 'col-md-6': folderProgress.length== 1 ? 'col-md-12' : ''"
                  v-for="(data, index) in folderProgress"
                  v-bind:key="index"
                >
                  <folder-progress-graph
                    :folder_name="data.folder_name"
                    :total_count="data.course_count"
                    :passed_count="data.passed_course_count"
                    :failed_count="data.failed_course_count"
                  ></folder-progress-graph>
                </div>
              </div>
            </div>
          </div>
        </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            ></div>
            <div>
              <div
                class="user-eltable mb-3"
                v-if="employeeStatus === 'open' || !this.$route.query.course"
              >
                <h3 style="padding:10px;">
                  Open Courses
                </h3>

                <el-table
                  :data="tableData.filter(data => data.course_status === 2)"
                  row-key="id"
                  role="table"
                  class="empcoursesGrid"
                  header-row-class-name="thead-light custom-thead-light"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                >
                  <el-table-column min-width="220px" label="Course Name">
                    <template slot-scope="props">
                      <span>{{ props.row.course_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Due Date">
                    <template slot-scope="props">
                      <span
                        v-if="
                          currentDate(props.row.due_date) &&
                            props.row.course_status != '1'
                        "
                        style="color:red;"
                        ><b>{{ formattedDate(props.row.due_date) }}</b></span
                      >
                      <span v-else>{{
                        formattedDate(props.row.due_date)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Lesson Status">
                    <template slot-scope="props">
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 0"
                        ><b>Failed</b></span
                      >
                      <span
                        type="warning"
                        style="color: #ffd600;"
                        v-if="props.row.course_status === 2"
                        ><b>Open</b></span
                      >
                      <span
                        type="success"
                        style="color: #05bf70;"
                        v-if="props.row.course_status === 1"
                        ><b>Passed</b></span
                      >
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 3"
                        ><b>Expired</b></span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200px"
                    label="Estimated Time (in mins)"
                    prop="name"
                  >
                    <template slot-scope="props">
                      <span>{{ props.row.course_length }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="150px" label="Action">
                    <template slot-scope="props">
                      <base-button
                        name="Pay Course"
                        v-if="is_payByEmployeeOn && !props.row.is_employee_paid"
                        @click.prevent="PayCourse(props.row)"
                        type="info"
                      >
                        {{ "Pay" }}
                      </base-button>
                      <base-button
                        name="Take Course"
                        v-else
                        @click.prevent="
                          takingCourse(
                            props.row.id,
                            props.row.due_date,
                            props.row.is_2fa_required
                          )
                        "
                        type="success"
                      >
                        {{
                          props.row.course_attempts !== undefined &&
                          props.row.course_attempts.length > 0
                            ? "Retake Course "
                            : "Take Course"
                        }}
                      </base-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                class="user-eltable mb-3"
                v-if="employeeStatus === 'expired' || !this.$route.query.course"
              >
                <h3 style="padding:10px;">
                  Expired Courses
                </h3>

                <el-table
                  :data="tableData.filter(data => data.course_status === 3)"
                  row-key="id"
                  role="table"
                  class="empcoursesGrid"
                  header-row-class-name="thead-light custom-thead-light"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                >
                  <el-table-column min-width="200px" label="Course Name">
                    <template slot-scope="props">
                      <span>{{ props.row.course_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Due Date">
                    <template slot-scope="props">
                      <span
                        v-if="
                          currentDate(props.row.due_date) &&
                            props.row.course_status != '1'
                        "
                        style="color:red;"
                        ><b>{{ formattedDate(props.row.due_date) }}</b></span
                      >
                      <span v-else>{{
                        formattedDate(props.row.due_date)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Lesson Status">
                    <template slot-scope="props">
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 0"
                        ><b>Failed</b></span
                      >
                      <span
                        type="warning"
                        style="color: #ffd600;"
                        v-if="props.row.course_status === 2"
                        ><b>Open</b></span
                      >
                      <span
                        type="success"
                        style="color: #05bf70;"
                        v-if="props.row.course_status === 1"
                        ><b>Passed</b></span
                      >
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 3"
                        ><b>Expired</b></span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200px"
                    label="Estimated Time (in mins)"
                    prop="name"
                  >
                    <template slot-scope="props">
                      <span>{{ props.row.course_length }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="150px" label="Action">
                    <template slot-scope="props">
                      <base-button
                        v-if="is_payByEmployeeOn && !props.row.is_employee_paid"
                        @click.prevent="PayCourse(props.row)"
                        type="info"
                      >
                        {{ "Pay" }}
                      </base-button>
                      <router-link
                        v-else
                        :to="
                          '/course_instructions?id=' +
                            props.row.id +
                            '&due_date=' +
                            props.row.due_date
                        "
                      >
                        <base-button type="success"  name="Take Course" class="custom-btn">
                          {{
                            props.row.course_attempts !== undefined &&
                            props.row.course_attempts.length > 0
                              ? "Retake Course "
                              : "Take Course"
                          }}
                        </base-button>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div
                class="user-eltable mb-3"
                v-if="employeeStatus === 'failed' || !this.$route.query.course"
              >
                <h3 style="padding:10px;">
                  Failed Courses
                </h3>

                <el-table
                  :data="tableData.filter(data => data.course_status === 0)"
                  row-key="id"
                  role="table"
                  class="empcoursesGrid"
                  header-row-class-name="thead-light custom-thead-light"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                >
                  <el-table-column min-width="200px" label="Course Name">
                    <template slot-scope="props">
                      <span>{{ props.row.course_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Due Date">
                    <template slot-scope="props">
                      <span
                        v-if="
                          currentDate(props.row.due_date) &&
                            props.row.course_status != '1'
                        "
                        style="color:red;"
                        ><b>{{ formattedDate(props.row.due_date) }}</b></span
                      >
                      <span v-else>{{
                        formattedDate(props.row.due_date)
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Lesson Status">
                    <template slot-scope="props">
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 0"
                        ><b>Failed</b></span
                      >
                      <span
                        type="warning"
                        style="color: #ffd600;"
                        v-if="props.row.course_status === 2"
                        ><b>Open</b></span
                      >
                      <span
                        type="success"
                        style="color: #05bf70;"
                        v-if="props.row.course_status === 1"
                        ><b>Passed</b></span
                      >
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 3"
                        ><b>Expired</b></span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200px"
                    label="Estimated Time (in mins)"
                    prop="name"
                  >
                    <template slot-scope="props">
                      <span>{{ props.row.course_length }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="150px" label="Action">
                    <template slot-scope="props">
                      <base-button
                        name="Pay Course"
                        v-if="is_payByEmployeeOn && !props.row.is_employee_paid"
                        @click.prevent="PayCourse(props.row)"
                        type="info"
                      >
                        {{ "Pay" }}
                      </base-button>
                      <router-link
                        v-else
                        :to="
                          '/course_instructions?id=' +
                            props.row.id +
                            '&due_date=' +
                            props.row.due_date
                        "
                      >
                        <base-button type="success"  name="Take Course" class="custom-btn">
                          {{
                            props.row.course_attempts !== undefined &&
                            props.row.course_attempts.length > 0
                              ? "Retake Course "
                              : "Take Course"
                          }}
                        </base-button>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                class="user-eltable mb-3"
                v-if="employeeStatus === 'passed' || !this.$route.query.course"
              >
                <h3 style="padding:10px;">
                  Passed Courses
                </h3>

                <el-table
                  :data="tableData.filter(data => data.course_status === 1)"
                  row-key="id"
                  role="table"
                  class="empcoursesGrid"
                  header-row-class-name="thead-light custom-thead-light"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                >
                  <el-table-column min-width="200px" label="Course Name">
                    <template slot-scope="props">
                      <span>{{ props.row.course_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Expiration Date">
                    <template slot-scope="props">
                      <span
                        class="mx-2"
                        v-for="certificate in props.row.certificates"
                        :key="certificate.id"
                      >
                        <span
                          v-if="currentDate(certificate.certificate_exp)"
                          style="color:red;"
                          ><b>{{
                            formattedDate(certificate.certificate_exp)
                          }}</b></span
                        >

                        <span v-else>{{
                          formattedDate(certificate.certificate_exp)
                        }}</span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="200px" label="Lesson Status">
                    <template slot-scope="props">
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 0"
                        ><b>Failed</b></span
                      >
                      <span
                        type="warning"
                        style="color: #ffd600;"
                        v-if="props.row.course_status === 2"
                        ><b>Open</b></span
                      >
                      <span
                        type="success"
                        style="color: #05bf70;"
                        v-if="props.row.course_status === 1"
                        ><b>Passed</b></span
                      >
                      <span
                        type="danger"
                        style="color:#f50636;"
                        v-if="props.row.course_status === 3"
                        ><b>Expired</b></span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200px"
                    label="Estimated Time (in mins)"
                    prop="name"
                  >
                    <template slot-scope="props">
                      <span>{{ props.row.course_length }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="150px" label="Action">
                    <template slot-scope="props">
                      <span
                        class="mx-2"
                        v-for="certificate in props.row.certificates"
                        :key="certificate.id"
                      >
                        <el-tooltip content="Preview" placement="top">
                          <a
                            v-if="
                              certificate.manual == 0 &&
                                certificate.is_proctored_exam == 0
                            "
                            :href="
                              baseUrl +
                                '/downloadCourseCertificate/preview/' +
                                props.row.id +
                                '/' +
                                user_id +
                                '/' +
                                props.row.certificate_id
                            "
                            data-toggle="tooltip"
                            data-original-title="Preview"
                            target="_blank"
                          >
                            <span>
                              <i name="Preview Certificate" class="text-success fa fa-eye mr-2"></i> </span
                          ></a>
                          <a
                            v-else-if="certificate.manual == 1"
                            target="_blank"
                            download
                            :href="certificate.certificate_url"
                            data-toggle="tooltip"
                            data-original-title="preview"
                          >
                            <span>
                              <i name="Preview Certificate" class="text-success fa fa-eye mr-2"></i> </span
                          ></a>
                          <a
                            v-else-if="certificate.is_proctored_exam == 1"
                            class="linkColor"
                            @click.prevent="
                              getProctoredExamCertificate(
                                certificate.certificate_url
                              )
                            "
                            data-toggle="tooltip"
                            data-original-title="preview"
                          >
                            <span>
                              <i class="text-success fa fa-eye mr-2"></i> </span
                          ></a>
                        </el-tooltip>

                        <el-tooltip content="Download" placement="top">
                          <a

                            v-if="
                              certificate.manual == 0 &&
                                certificate.is_proctored_exam == 0
                            "
                            :href="
                              baseUrl +
                                '/downloadCourseCertificate/download/' +
                                props.row.id +
                                '/' +
                                user_id +
                                '/' +
                                props.row.certificate_id
                            "
                            data-toggle="tooltip"
                            data-original-title="Download"
                            target="_blank"
                          >
                            <span>
                              <i  class="text-warning fa fa-download"></i> </span
                          ></a>

                          <a

                            v-else-if="certificate.manual == 1"
                            target="_blank"
                            download
                            :href="certificate.certificate_url"
                            data-toggle="tooltip"
                            data-original-title="Download"
                          >
                            <span>
                              <i  class="text-warning fa fa-download"></i> </span
                          ></a>
                        </el-tooltip>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="show2faModal">
      <h3 slot="header" class="title title-up text-primary text-center">
          <u>Two-Factor Authentication Required to Continue</u>
      </h3>
      <form>
        <div class="row">
          <div class="col-md-12">
            <h5>
                This course requires a Two-Factor Authentication to verify your identity.  We will send you a verification code to your registered phone number. If you do not have a number on file, please enter it below.
            </h5>
          </div>
            <div class="col-md-12">
                <label
                ><b>Registered Phone Number:</b></label
                >
            </div>

            <div class="col-md-7">
                <base-input
                    type="tel"
                    placeholder="Enter new phone number"
                    v-model="newphonenumber"
                    @input="acceptNumber"
                ></base-input>
            </div>
          <div
            class="col-md-5"
            style="padding:0px !important"
            v-if="changePhoneNum"
          >
            <label>
              (<a class="linkColor" @click.prevent="cancelPhone">Cancel</a
              >)</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>Select verification channel:</label>
          </div>
          <div class="col-md-3">
            <input
              type="radio"
              name="verificationtype"
              value="sms"
              v-model="verificationType"
            />
            Text
          </div>
          <div class="col-md-3">
            <input
              type="radio"
              name="verificationtype"
              value="call"
              v-model="verificationType"
            />
            Call
          </div>
          <div class="col-md-12 mt-4">
            <base-button
              name="Send Verification"
              type="success"
              @click.prevent="sendVerification"
              size="sm"
              >Start Verification</base-button
            >
          </div>
        </div>
      </form>
    </modal>
    <modal :show.sync="askOtpModal">
      <h3 slot="header" class="title title-up text-primary text-center">
        Enter Code
      </h3>
      <form>
        <div class="row">
          <div class="col-md-6">
            <base-input
              type="number"
              v-model="otp"
              placeholder="Enter Code"
            ></base-input>
          </div>
          <div class="col-md-6">
            <base-button name="Verify OTP" type="success" @click.prevent="verifyOtp" size="sm"
              >Verify</base-button
            >
          </div>
        </div>
      </form>
    </modal>
    <modal :show.sync="payCourseModel">
      <h4 slot="header" style="color:#444C57" class="modal-title mb-0">
        Payment
      </h4>

      <pay-by-employee
        v-if="payCourseModel"
        type="payByEmployee"
        :amountPayable="discountedCost"
        :orignalAmount="orignalCost"
        :discount="is_payByEmployeeDiscount"
        :address="this.address"
        :city="this.city"
        :state="this.state"
        :zipcode="this.zipcode"
        v-on:payClicked="payClicked"
      />
    </modal>
    <modal :show.sync="purchaseNewCourseModal">
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showCourseList">
        <!-- <img width="15px" src="/img/icons/common/back-arrow.svg" class="mr-3" /> -->
         Course
        Library
      </h3>
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showCourseDetails">
        <img
          width="15px"
          style="cursor: pointer"
          src="/img/icons/common/back-arrow.svg"
          @click="showListView"
          class="mr-3"
        />
        Course Details
      </h3>
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showCheckoutModal && selectedEmployees.length==0">
        <img
          width="15px"
          style="cursor: pointer"
          src="/img/icons/common/back-arrow.svg"
          @click="showListView"
          class="mr-3"
        />
        Course Checkout
      </h3>
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showCheckoutModal && selectedEmployees.length > 0 " >
        <img
          width="15px"
          style="cursor: pointer"
          src="/img/icons/common/back-arrow.svg"
          @click="showEmpScreen()"
          class="mr-3"
        />
        Course Checkout
      </h3>
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showPaymentWindow" >
        <img
          width="15px"
          style="cursor: pointer"
          src="/img/icons/common/back-arrow.svg"
          @click="backToCheckout()"
          class="mr-3"
        />
        Payment
      </h3>
      <h3 slot="header" class="d-flex align-items-center m-0" v-if="showEmpListModal">
        <img
          width="15px"
          style="cursor: pointer"
          src="/img/icons/common/back-arrow.svg"
          @click="backToCouse"
          class="mr-3"
        />
        Select Employees
      </h3>

      <div class="course-content-modal course-selection" v-show="showCourseList">
        <!-- <el-select v-model="value1" multiple placeholder="Select" class="w-100">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <base-input
          label="Select Course(s)"
          v-model="searchQuery"
          prepend-icon="fas fa-search"
          placeholder="Search"
        >
        </base-input>
        <div
          class="course-selection-home border rounded-lg shadow p-3 mb-2"
          style="max-height: 330px; overflow-y: auto"
        >
          <div
            v-for="list in FilterlistCourses"
            :key="list.id"
            class="course-block d-flex justify-content-between align-items-center border-bottom pb-3 mb-3"
            @click="toogleCourse(list)"
          >
            <div class="d-flex align-items-center">
              <img
          width="80"
          height="80"
          class="mr-3"
          :src="list.image_url ? list.image_url : `https://placehold.co/80x80`"
          alt=""
              />
              <div class="">
          <h3 class="mb-2">{{ list.name }}</h3>
          <span class="link-withsvg" @click.stop="showCourseDetailsView(list)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8807 7.454C15.2954 6.174 13 2 8.00004 2C3.00004 2 0.704703 6.174 0.11937 7.454C0.0407951 7.62553 0.00012207 7.81199 0.00012207 8.00067C0.00012207 8.18934 0.0407951 8.3758 0.11937 8.54733C0.704703 9.826 3.00004 14 8.00004 14C13 14 15.2954 9.826 15.8807 8.546C15.9591 8.37466 15.9997 8.18843 15.9997 8C15.9997 7.81157 15.9591 7.62534 15.8807 7.454ZM8.00004 12.6667C3.79537 12.6667 1.83337 9.08933 1.33337 8.00733C1.83337 6.91067 3.79537 3.33333 8.00004 3.33333C12.1947 3.33333 14.1574 6.89533 14.6667 8C14.1574 9.10467 12.1947 12.6667 8.00004 12.6667Z" fill="#525252"/>
                    <path d="M8.00008 4.66699C7.34081 4.66699 6.69635 4.86249 6.14818 5.22876C5.60002 5.59503 5.17278 6.11563 4.92048 6.72471C4.66819 7.3338 4.60218 8.00402 4.7308 8.65063C4.85942 9.29723 5.17689 9.89117 5.64306 10.3573C6.10924 10.8235 6.70318 11.141 7.34978 11.2696C7.99639 11.3982 8.66661 11.3322 9.27569 11.0799C9.88478 10.8276 10.4054 10.4004 10.7716 9.85223C11.1379 9.30406 11.3334 8.6596 11.3334 8.00033C11.3324 7.1166 10.9808 6.26936 10.3559 5.64447C9.73104 5.01958 8.88381 4.66805 8.00008 4.66699ZM8.00008 10.0003C7.60452 10.0003 7.21784 9.88303 6.88894 9.66326C6.56004 9.4435 6.3037 9.13114 6.15232 8.76569C6.00095 8.40024 5.96134 7.99811 6.03851 7.61015C6.11568 7.22218 6.30616 6.86582 6.58587 6.58611C6.86557 6.30641 7.22194 6.11593 7.6099 6.03875C7.99786 5.96158 8.4 6.00119 8.76545 6.15257C9.1309 6.30394 9.44326 6.56029 9.66302 6.88918C9.88278 7.21808 10.0001 7.60476 10.0001 8.00033C10.0001 8.53076 9.78937 9.03947 9.4143 9.41454C9.03922 9.78961 8.53052 10.0003 8.00008 10.0003Z" fill="#525252"/>
                  </svg>
            View Detail</span>
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <span>
          <input
            type="checkbox"
            :id="'list-course' + list.id"
            :checked="isCourseSelected(list)"
            @click.stop="toogleCourse(list)"
            class="mb-2"
          />
              </span>
              <b>$ {{ list.cost }}</b>
            </div>
          </div>
        </div>

        <div class="text-center mt-5">
          <base-button
            type="primary"
            size="lg"
            class="w-100 mb-3"
            @click="purchaseClick(1)"
          >
            Purchase For Myself
          </base-button>
          <base-button
            v-if="hot_user=='company-admin'"
            type="primary"
            outline="true"
            size="lg"
            class="w-100"
            @click="purchaseClick(0)"
          >
            Purchase For Employees
          </base-button>
        </div>
        <div class="clearfix"></div>
      </div>

      <div v-show="showCourseDetails" class="course-content-modal course-detail">
        <img
          width="100%"
          style="max-height: 250px"
          class="mb-3"
          :src="
            showSingleCourse.image_url
              ? showSingleCourse.image_url
              : `https://vumbnail.com/${showSingleCourse.video}.jpg`"
          alt="image"
        />
        <h2 class="d-flex justify-content-between align-items-center">
          <span class="head mr-5">{{ showSingleCourse.name }} </span>
          <span class="price">${{ showSingleCourse.cost }}</span>
        </h2>
        <div class="review-area-wrap d-flex align-items-center mb-3">
          <div class="stars mr-3">
            <img width="18" class="mr-1" src="/img/icons/common/star.svg" alt="" />
            <img width="18" class="mr-1" src="/img/icons/common/star.svg" alt="" />
            <img width="18" class="mr-1" src="/img/icons/common/star.svg" alt="" />
            <img width="18" class="mr-1" src="/img/icons/common/star.svg" alt="" />
            <img width="18" class="mr-1" src="/img/icons/common/star.svg" alt="" />
          </div>
          <span class="review-content"><b>4.0 (4.2k reviews)</b></span>
        </div>
        <p v-html="showSingleCourse.description"></p>
        <div class="text-center mt-5">
          <base-button type="primary" size="lg" class="w-100 mb-2"> Buy Now </base-button>
        </div>
      </div>

      <div v-show="showEmpListModal" class="course-content-modal select-employe">
  <base-input
    v-model="searchEmp"
    prepend-icon="fas fa-search"
    placeholder="Search"
  >
  </base-input>

  <div class="search-dropdown" style="max-height: 300px; overflow-y: auto">
    <ul class="border p-0 m-0 rounded-lg">

      <!-- Select All / Deselect All Checkbox -->
      <li class="d-flex justify-content-between p-3 border-bottom">
        <span><strong>Select All</strong></span>
        <span>
          <input
            type="checkbox"
            id="select-all"
            v-model="selectAll"
            @change="toggleAllEmployees"
            class="m-0"
          />
        </span>
      </li>

      <li v-for="(emp, index) in FilterlistEmployee" :key="emp.id" class="d-flex justify-content-between p-3 border-bottom">
        <span>{{ emp.full_name }} </span>
        <span>
          <input
            type="checkbox"
            :id="'list-course' + index"
            v-model="selectedEmployees"
            :value="emp"
            class="m-0"
          />
        </span>
      </li>
    </ul>
  </div>

  <div class="text-center mt-5">
    <base-button type="primary" size="lg" class="w-100 mb-2" @click.prevent="showCheckoutModalEmps">
      Next
    </base-button>
  </div>
</div>

      <div v-show="showCheckoutModal"  class="course-content-modal buy-course-modal-wrap">
        <div style="max-height: 360px; overflow-y: auto">
        <div
          v-for="course in selectedCourses"
          :key="course.id"
          class="course-block d-flex justify-content-between align-items-center border rounded-lg shadow p-3 mb-2"
        >
          <div class="d-flex align-items-center">
            <img
              width="80"
              height="80"
              class="mr-3"
              :src="course.image_url ? course.image_url : `https://vumbnail.com/${course.video}.jpg`"
              alt=""
            />
            <div class="" @click.prevent="showCourseDetailsView(course)">
              <h3 class="mb-2">{{ course.name }}</h3>
              <span class="link-withsvg">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8807 7.454C15.2954 6.174 13 2 8.00004 2C3.00004 2 0.704703 6.174 0.11937 7.454C0.0407951 7.62553 0.00012207 7.81199 0.00012207 8.00067C0.00012207 8.18934 0.0407951 8.3758 0.11937 8.54733C0.704703 9.826 3.00004 14 8.00004 14C13 14 15.2954 9.826 15.8807 8.546C15.9591 8.37466 15.9997 8.18843 15.9997 8C15.9997 7.81157 15.9591 7.62534 15.8807 7.454ZM8.00004 12.6667C3.79537 12.6667 1.83337 9.08933 1.33337 8.00733C1.83337 6.91067 3.79537 3.33333 8.00004 3.33333C12.1947 3.33333 14.1574 6.89533 14.6667 8C14.1574 9.10467 12.1947 12.6667 8.00004 12.6667Z" fill="#525252"/>
                    <path d="M8.00008 4.66699C7.34081 4.66699 6.69635 4.86249 6.14818 5.22876C5.60002 5.59503 5.17278 6.11563 4.92048 6.72471C4.66819 7.3338 4.60218 8.00402 4.7308 8.65063C4.85942 9.29723 5.17689 9.89117 5.64306 10.3573C6.10924 10.8235 6.70318 11.141 7.34978 11.2696C7.99639 11.3982 8.66661 11.3322 9.27569 11.0799C9.88478 10.8276 10.4054 10.4004 10.7716 9.85223C11.1379 9.30406 11.3334 8.6596 11.3334 8.00033C11.3324 7.1166 10.9808 6.26936 10.3559 5.64447C9.73104 5.01958 8.88381 4.66805 8.00008 4.66699ZM8.00008 10.0003C7.60452 10.0003 7.21784 9.88303 6.88894 9.66326C6.56004 9.4435 6.3037 9.13114 6.15232 8.76569C6.00095 8.40024 5.96134 7.99811 6.03851 7.61015C6.11568 7.22218 6.30616 6.86582 6.58587 6.58611C6.86557 6.30641 7.22194 6.11593 7.6099 6.03875C7.99786 5.96158 8.4 6.00119 8.76545 6.15257C9.1309 6.30394 9.44326 6.56029 9.66302 6.88918C9.88278 7.21808 10.0001 7.60476 10.0001 8.00033C10.0001 8.53076 9.78937 9.03947 9.4143 9.41454C9.03922 9.78961 8.53052 10.0003 8.00008 10.0003Z" fill="#525252"/>
                  </svg>
                 View detail
                </span>
            </div>
          </div>
          <div class="d-flex flex-column align-items-end">
            <b class="mb-2">${{ course.cost }}</b>
            <a href="#" class="remove-anchor" @click="toogleCourse(course)"
              >Remove <span>x</span></a
            >
          </div>
        </div>
        <!-- <div class="course-block d-flex justify-content-between align-items-center border rounded-lg shadow p-3 mb-2">

          <div class="d-flex flex-column align-items-end">
            <b class="mb-2">$50.00</b>
            <a href="#" class="remove-anchor">Remove <span>x</span></a>
          </div>
        </div> -->

      </div>
        <div class="payment-summary-block mt-4">
          <h2>Summary</h2>
          <h3 v-show="selectedEmployees.length>0">Selected Employee {{ selectedEmployees.length!=0 ? selectedEmployees.length : 1  }}</h3>
          <div  v-show="selectedEmployees.length>0"  class="payment-summary-block-inner border-bottom pb-3 mb-3">
            <div v-for="course in selectedCourses" :key="course.id" class="d-flex justify-content-between mb-2">
              <span>{{course.name}}</span>
              <span>${{basedOnEmployee(course.cost)}}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2 summary-block-inner-font">
            <span>Total Amount</span>
            <!-- <strong>${{ empTotalCost  }}</strong> -->
            <strong>${{ calculateTotalCost() }}</strong>
          </div>
        </div>

        <div class="text-center mt-5">
          <base-button
            type="primary"
            size="lg"
            @click="PayCourse(selectedCourses,'purchase')"
            class="w-100 mb-2"
          >
            Next
          </base-button>
        </div>
      </div>

      <div v-show="showPaymentWindow" class="course-content-modal payment-modal">
        <div class="row">


          <div class="col-md-12">
            <pay-by-employee type="payByEmployee" :amountPayable="discountedCost" :orignalAmount="orignalCost"
            :discount="is_payByEmployeeDiscount" :address="address" :city="city" :state="state"
            :zipcode="zipcode" v-on:payClicked="paymentClicked" />
          </div>
          <!-- <div class="col-md-12">
            <label class="form-control-label">Billing Address
              <span class="requireField">*</span>
            </label>
            <span placeholder="Address">
              <div class="form-group">
                <div class="">
                  <input type="text" v-model="address" placeholder="Address" class="form-control">
                </div>
              </div>
            </span>
          </div> -->
          <!-- <div class="col-md-4">
            <label class="form-control-label">City <span class="requireField">*</span></label>
            <span placeholder="City">
              <div class="form-group">
                <div><input type="text" v-model="city" placeholder="City" class="form-control" valid="true">
                </div>
              </div>
            </span>
          </div>
          <div class="col-md-4"><label class="form-control-label">State <span class="requireField">*</span></label>
            <span placeholder="State">
              <div class="form-group">
                <div><input type="text" v-model="state" placeholder="State" class="form-control" valid="true">
                </div>
              </div>
            </span>
          </div>
          <div class="col-md-4"><label class="form-control-label">Zipcode <span class="requireField">*</span></label>
            <span placeholder="Zipcode">
              <div class="form-group">
                <div class="">
                  <input type="text" v-model="zipcode" placeholder="Zipcode" class="form-control">
                </div>
              </div>
            </span>
          </div>
        </div>
          <div class="row">
            <div class="col-12 mb-2">
              <b>Amount Payable :  $ </b>
              {{ this.orignalCost }}
            </div>
          </div>
        <div class="text-center mt-5">
          <base-button type="primary" size="lg" class="w-100 mb-2" @click.prevent="paymentClicked()">
          Confirm Purchase
          </base-button>
        </div> -->
      </div>

      </div>

      <div class="course-content-modal buy-course-modal-wrap d-none">
        <div class="thank-header d-flex justify-content-center align-items-center flex-column">
            <img class="mb-2" width="150" src="/img/icons/common/thankyou.svg" alt="" />
            <h2 class="mb-0">Congratulations</h2>
            <p class="mb-4">You've Purchased Course Successfully</p>
        </div>
        <div class="course-purchase-detail rounded-lg py-3">
          <ul class="p-0 m-0">
            <li class="d-flex justify-content-between px-3 pb-3 mb-3">
              <span>Name</span>
              <strong>Adriana Corado</strong>
            </li>
            <li class="d-flex justify-content-between px-3 pb-3 mb-3">
              <span>Date</span>
              <strong>20 july 2025</strong>
            </li>
            <li class="d-flex justify-content-between px-3 pb-3 mb-3">
              <span>Course Name</span>
              <strong>Food Handler Training</strong>
            </li>
            <li class="d-flex justify-content-between px-3">
              <span>Total Amount</span>
              <strong>$50.00</strong>
            </li>
          </ul>
        </div>

        <div class="text-center mt-5">
          <base-button type="primary" size="lg" class="w-100 mb-2">
          Back To Home
          </base-button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Checkbox } from "element-ui";
import PayByEmployee from "./PayByEmployee.vue";
// import PayPurchaseCourse from "./PayPurchaseCourse.vue";
import clientPaginationMixin from "../Tables/PaginatedTables/clientPaginationMixin";
import Swal from "sweetalert2";
import moment from "moment";
import FolderProgressGraph from "@/views/Super/FolderProgressGraph.vue";
import { StripeElementCard } from '@vue-stripe/vue-stripe';

// import OpenCourses from "./EmployeeCourses/OpenCourses.vue";

export default {
  mixins: [clientPaginationMixin],
  components: {
    PayByEmployee,
    StripeElementCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Checkbox.name]: Checkbox,
    [TableColumn.name]: TableColumn,
    FolderProgressGraph,
  },
  data() {
    return {
      newPayModal:false,
      publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      postalCode: true,
      loading: false,
      baseUrl: this.$baseUrl,
      height: 500,
      options: {},
      playerReady: false,
      fullPage: true,
      hot_user: "",
      hot_token: "",
      purchaseNewCourseModal: false,
      checked: true,
      // courseDetailModal: false,
      // buyCoursesModal: false,
      user_id: "",
      config: "",
      passed: "",
      opened: "",
      failed: "",
      expired: "",
      company_id: "",
      searchQuery: "",
      searchEmp: "",
      tableData: [],
      selectedRows: [],
      videos: [],
      employeeStatus: "",
      show2faModal: false,
      is_authenticated: 0,
      phonenumber: "",
      newphonenumber: "",
      verificationType: "sms",
      changePhoneNum: false,
      askOtpModal: false,
      is_payByEmployeeOn: 0,
      payCourseModel: false,
      is_payByEmployeeDiscount: 0,
      otp: "",
      discountedCost: 0,
      orignalCost: 0,
      address: "",
      city: "",
      state: "",
      zipcode: "",
      addressAvailble: true,
      folderProgress: "",
      openCourses: 0,
      expiredCourses: 0,
      failedCourses: 0,
      passedCourses: 0,
      is_open_show: true,
      is_expired: false,
      is_failed: false,
      is_passed: false,
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      value1: [],
      listCourses: [],
      selectedCourses: [],
      showSingleCourse: "",
      showCourseList: true,
      showCourseDetails: false,
      showCheckoutModal: false,
      empTotalCost: 0,
      showPaymentWindow: false,
      empList:[],
      selectedEmployees:[],
      showEmpListModal:false,
      card: {
        cardNumber: "",
        expire: "",
        token: null,
      },
      formDataVal: {
        payment: {},
        selectedCourses: [],
        user_id: ""
      },
      selectAll:false,

    };
  },
  computed: {
    // Filters courses based on search query
    FilterlistCourses() {
      if (!this.searchQuery) return this.listCourses;
      return this.listCourses.filter((course) =>
        course.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    // Filters courses based on search query
    FilterlistEmployee() {
      if (!this.searchEmp) return this.empList;
      return this.empList.filter((emp) =>
        emp.full_name.toLowerCase().includes(this.searchEmp.toLowerCase())
      );
    },
  },
  mounted(){
  //   this.$nextTick(() => {
  //   console.log("Stripe component loaded:", this.$refs.elementRef);
  // });
  },

  created() {
    // console.log("Stripe Public Key:", this.publicKey);
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");

      this.hot_token = localStorage.getItem("hot-token");
      this.company_id = localStorage.getItem("hot-company-id");
      if (this.hot_user === "company-admin") {
        this.user_id = localStorage.getItem("hot-admin-id");
        this.company_id = localStorage.getItem("hot-user-id");
      } else {
        this.user_id = localStorage.getItem("hot-user-id");
      }
    }
    if (this.$route.query.course) {
      if (this.$route.query.course === "open") {
        this.is_open_show = true;
      } else if (this.$route.query.course === "passed") {
        this.is_passed = true;
        this.is_open_show = false;
      } else if (this.$route.query.course === "failed") {
        this.is_failed = true;
        this.is_open_show = false;
      } else if (this.$route.query.course === "expired") {
        this.is_expired = true;
        this.is_open_show = false;
      }
      // this.employeeStatus = this.$route.query.course;
    }
    this.fetchData();
    this.folderProgressData();
    this.listOfCourses();
    this.listOfEmployees();
  },
  methods: {
    toggleAllEmployees() {
    if (this.selectAll) {
      this.selectedEmployees = [...this.FilterlistEmployee]; // Select all employees
    } else {
      this.selectedEmployees = []; // Deselect all employees
    }
  },
    purchaseModal(){
      this.purchaseNewCourseModal=true;
    this.$nextTick(() => {
      if (this.$refs.stripeCard) {
        this.$refs.stripeCard.unmount();
        this.$refs.stripeCard.mount();
      }
    });
    },
  //   loadStripe() {
  //   this.$nextTick(() => {
  //     if (this.$refs.elementRef) {
  //       console.log("Stripe Element Reloaded:", this.$refs.elementRef);
  //     }
  //   });
  // },
    paymentClicked(card, address) {
      this.loading = true;
      // type condition of self or employee
      let payment = {
      payment_type: "payByEmployee",
      cardholder_street_address: "",
      cardholder_zip: "",
      cardholder_city: "",
      cardholder_state: "",
      transaction_amount: "",
      token: card.token,
      card_number: card.cardNumber,
      card_exp_date: card.expire,
      };
      payment.cardholder_state = address.state;
      payment.cardholder_street_address = address.address;
      payment.cardholder_zip = address.zipcode;
      payment.cardholder_city = address.city;
      payment.transaction_amount = this.calculateTotalCost();



      this.formDataVal.payment = payment;
      this.formDataVal.selectedCourses = this.selectedCourses.map(course => course.id);
      this.formDataVal.user_id = this.user_id;
      this.formDataVal.company_id = this.company_id;
      this.formDataVal.orignal_amount = this.orignalCost;
      this.formDataVal.actual_amount = this.discountedCost;
      this.formDataVal.pay_by_employee_dicount = this.is_payByEmployeeDiscount;
      this.formDataVal.selectedEmployees = this.selectedEmployees.map(emp => emp.id);

      this.$http
      .post("/purchase/self", this.formDataVal)
      .then((resp) => {
        Swal.fire({
        title: "Success!",
        html: `Amount Paid Sucessfully.`,
        icon: "success",
        confirmButton: "btn btn-success",
        confirmButtonText: "Ok",
        });
        this.loading = false;
        setTimeout(() => {
        location.reload();
        }, 500);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status === 422) {
        return Swal.fire({
          title: "Error!",
          text: error.response.data.message,
          icon: "error",
        });
        } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
        });
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
   tokenCreated(token) {
        console.log("Stripe Token:", token);

      if (!token) {
        console.error("Stripe token generation failed!");
        return;
      }
      console.log("Stripe Token:", token);
      this.card.token = token;
    },
    backToCheckout(){
      this.showCheckoutModal = true;
      this.showPaymentWindow = false;
    },
    basedOnEmployee(price) {
      if(this.selectedEmployees.length===0) return  (parseFloat(price) * 1).toFixed(2);

      return (parseFloat(price) * this.selectedEmployees.length).toFixed(2);
    },
    calculateTotalCost() {
      return this.selectedCourses.reduce((total, course) => {
      return total + parseFloat(this.basedOnEmployee(course.cost));
      }, 0).toFixed(2);
    },

    showPaymentOption() {
      if (this.address == "" && this.zipcode == "") {
        this.addressAvailble = false;
      }
      this.showPaymentWindow = true;
      this.showCheckoutModal = false;
      this.showCourseDetails = false;
      this.showCourseList = false;
      this.showPaymentWindow = false;
      this.$nextTick(() => {
        this.showPaymentWindow = true;
        this.$nextTick(() => {
          if (this.$refs.stripeCard) {
            this.$refs.stripeCard.unmount();
            this.$refs.stripeCard.mount();
          }
        });
      });
    },
    purchaseClick(val) {
      if(this.selectedCourses.length < 1 ){
        return Swal.fire({
            title: "Error!",
            text: `Please Select atleast one course`,
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            confirmButtonText: "OK",
          })
      }
      let type = val === 1 ? "self" : "other";
      if(val==1){
        this.selectedEmployees=[];
        this.showCheckoutModal = true;
      }
      else if (val==0){
        this.showEmpListModal=true;
      }
      this.showCourseDetails = false;
      this.showCourseList = false;
    },
    showCheckoutModalEmps(){
      if(this.selectedEmployees.length < 1 ){
        return Swal.fire({
            title: "Error!",
            text: `Please Select atleast one Employee`,
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            confirmButtonText: "OK",
          })
      }
      this.showCourseDetails = false;
      this.showCourseList = false;
      this.showEmpListModal=false;
      this.showCheckoutModal = true;

    },
    async showCourseDetailsView(course) {
      this.showSingleCourse = course;
      this.showCourseDetails = true;
      this.showCourseList = false;
      this.showCheckoutModal=false;
    },
    backToCouse(){
      this.showCourseList = true;
      this.showEmpListModal=false;
      this.showCourseDetails = false;
      this.showCheckoutModal = false;
    },
    showEmpScreen(){
      this.showEmpListModal=true;
      this.showCourseDetails = false;
      this.showCheckoutModal = false;
      this.showCourseList = false;

    },
    showListView() {
      if(this.selectedEmployees.length!=0){

        this.showCourseList = false;
        this.showEmpListModal=true;
      }else{

        this.showEmpListModal=false;
        this.showCourseList = true;
      }
      this.showSingleCourse = "";
      this.showCourseDetails = false;
      this.showCheckoutModal = false;
      // this.showEmpListModal=false;
    },
    isCourseSelected(course) {
      return this.selectedCourses.some((selected) => selected.id === course.id);
    },
    toogleCourse(course) {
      const index = this.selectedCourses.findIndex(
        (selected) => selected.id === course.id
      );
      if (index > -1) {
        this.selectedCourses.splice(index, 1);
      } else {
        this.selectedCourses.push(course);
      }
      this.empTotalCost = this.selectedCourses
        .reduce((total, course) => total + course.cost, 0)
        .toFixed(2);
    },
    toogleEmp(emp) {
      const index = this.selectedEmployees.findIndex(
        (selected) => selected.id === emp.id
      );
      if (index > -1) {
        this.selectedEmployees.splice(index, 1);
      } else {
        this.selectedEmployees.push(emp);
      }
      // this.empTotalCost = this.selectedCourses
      //   .reduce((total, course) => total + course.cost, 0)
      //   .toFixed(2);
    },
    isEmployeeSelected(emp) {
      return this.selectedEmployees.some((selected) => selected.id === emp.id);
    },
    listOfEmployees() {
      this.$http
        .post("list/emp",{
          company_id : this.company_id
        })
        .then((response) => {
          this.empList = response.data;
        })
        .catch((error) => {
          console.log("API failed for loading progress data");
        });
    },
    listOfCourses() {
      this.$http
        .post("list/purchase-courses")
        .then((response) => {
          this.listCourses = response.data;
        })
        .catch((error) => {
          console.log("API failed for loading progress data");
        });
    },
    showOpen() {
      this.is_open_show = true;
      this.is_expired = false;
      this.is_failed = false;
      this.is_passed = false;
      this.fetchData();
    },
    showExpired() {
      this.is_open_show = false;
      this.is_expired = true;
      this.is_failed = false;
      this.is_passed = false;
      this.fetchData();
    },
    showPassed() {
      this.is_open_show = false;
      this.is_expired = false;
      this.is_failed = false;
      this.is_passed = true;
      this.fetchData();
    },
    showFailed() {
      this.is_open_show = false;
      this.is_expired = false;
      this.is_failed = true;
      this.is_passed = false;
      this.fetchData();
    },
    folderProgressData() {
      this.$http
        .get("employees/employeeCourseFolderProgress")
        .then((response) => {
          this.folderProgress = response.data;
        })
        .catch((error) => {
          console.log("API failed for loading progress data");
        });
    },
    PayCourse(row,type) {
      if(type=='purchase'){
        this.discountedCost = this.calculateTotalCost();
        this.orignalCost = this.calculateTotalCost();
        this.showPaymentWindow=true;
        this.showCheckoutModal=false;
        return;
      }
      this.discountedCost = row.discountedCost;
      this.orignalCost = row.course_cost;
      this.formData = {
        employee_id: this.user_id,
        company_id: this.company_id,
        course_id: row.id,
        orignal_amount: row.course_cost,
        pay_by_employee_dicount: this.is_payByEmployeeDiscount,
        actual_amount: this.discountedCost,
      };
      if (this.address == "" && this.zipcode == "") {
        this.addressAvailble = false;
      }
      this.payCourseModel = true;
    },
    takingCourse(course_id, due_date, is_2fa_required,playbtn) {
      if (is_2fa_required && this.is_authenticated == 0) {
        this.show2faModal = true;
      } else {
        if (this.editor !== "super-admin" && this.editor !== "sub-admin") {
          if(playbtn=true){
            let data = {
                event: "Clicked on Take Course",
            };
            this.$http
                .post("user/add_activity", data)
                .then((resp) => {
                })
                .catch(function () {
                })
                .finally(() => (this.loading = false));
          }
        }
        this.$router.push(
          "/course_instructions?id=" + course_id + "&due_date=" + due_date
        );
      }
    },
    sendVerification() {
      this.loading = true;
      let data = {
        phone_no: "",
        chanel_type: this.verificationType,
      };
      if (this.newphonenumber) {
        data.phone_no = this.newphonenumber;
      } else {
        data.phone_no = this.phonenumber;
      }
      this.$http
        .post("twilio/sendOTP", data)
        .then((resp) => {
          this.askOtpModal = true;
          Swal.fire({
            title: "Success!",
            text: "Text sent to your phone number.",
            icon: "success",
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    verifyOtp() {
      this.loading = true;
      let data = {
        phone_no: "",
        otp: this.otp,
      };
      if (this.newphonenumber) {
        data.phone_no = this.newphonenumber;
      } else {
        data.phone_no = this.phonenumber;
      }
      this.$http
        .post("twilio/VerifysendOTP", data)
        .then((resp) => {
          this.show2faModal = false;
          this.askOtpModal = false;
          Swal.fire({
            title: "Success!",
            text: "Verified successfully.",
            icon: "success",
          });
          this.fetchData();
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    formattedDate(data) {
      return moment(data).format("MM-DD-YYYY");
    },
    currentDate(duedate) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      if (currentDateWithFormat > duedate) {
        return true;
      } else {
        return false;
      }
    },
    fetchData() {
      this.loading = true;
      this.$http
        .post("employees/courses", {
          search: this.searchQuery,
          employee_id: this.user_id,
          employee_status: this.employeeStatus,
        })
        .then((resp) => {
          this.tableData = [];
          let course_data = resp.data.courses;
          let employee_data = resp.data.employee;
          this.openCourses = resp.data.open_count;
          this.expiredCourses = resp.data.expired_count;
          this.failedCourses = resp.data.failed_count;
          this.passedCourses = resp.data.passed_count;

          this.address = employee_data.address;
          this.state = employee_data.state;
          this.city = employee_data.city;
          this.zipcode = employee_data.zipcode;

          this.is_authenticated = employee_data.is_2f_authenticated;
          this.phonenumber = employee_data.phone_num;

          let payby_employee = resp.data.company_pay_by_employee;
          if (payby_employee != null) {
            this.company_id = payby_employee.company_id;
            this.is_payByEmployeeOn = payby_employee.pay_employee_status;
            this.is_payByEmployeeDiscount = payby_employee.pay_employee_discount;
          }
          for (let data of course_data) {
            let obj = {
              id: data.course_id,
              course_name: data.name,
              course_length: data.length,
              course_cost: data.cost,
              img: data.image_url
                ? data.image_url
                : `https://vumbnail.com/${data.video}.jpg`,
              discountedCost: "",
              due_date: data.employee_course_due_date,
              course_status: data.employee_course_status,
              is_2fa_required: "",
              is_employee_paid: "",
              certificates: [],
              certificate_id: "",
            };

            if (data.employee_certiifcates) {
              obj.certificate_id = data.employee_certiifcates[0].id;
            }
            if (data.course_paid_status === 0) {
              obj.discountedCost =
                obj.course_cost - obj.course_cost * (this.is_payByEmployeeDiscount / 100);
              obj.is_employee_paid = false;
            } else {
              obj.is_employee_paid = true;
            }
            if (data.is_2fa_required === 1) {
              obj.is_2fa_required = true;
            } else {
              obj.is_2fa_required = false;
            }
            if (data.employee_certiifcates) {
              let certificate_data = data.employee_certiifcates;
              for (let certificate of certificate_data) {
                let certificates_obj = {
                  certificate_exp: certificate.certificate_expiration_date,
                  certificate_url: certificate.certificate_url,
                  is_coursefolder: certificate.is_coursefolder_certificate,
                  manual: certificate.manual,
                  is_proctored_exam: certificate.is_proctored_exam,
                };
                obj.certificates.push(certificates_obj);
              }
            }
            this.tableData.push(obj);
          }
        })
        .finally(() => (this.loading = false));
    },
    generatePreviewCertificate(id, user_id, certificate_id, is_coursefolder) {
      window.open(
        this.baseUrl +
          "/downloadCourseCertificate/preview/" +
          id +
          "/" +
          user_id +
          "/" +
          certificate_id +
          "/" +
          is_coursefolder
      );
    },
    generateDownloadCertificate(id, user_id, certificate_id, is_coursefolder) {
      window.open(
        this.baseUrl +
          "/downloadCourseCertificate/download/" +
          id +
          "/" +
          user_id +
          "/" +
          certificate_id +
          "/" +
          is_coursefolder
      );
    },
    previewManualCertificate(url) {
      window.open(url);
    },
    downloadManualCertificate(url) {
      window.open(url, "download");
    },
    getProctoredExamCertificate: function (certificateURL) {
      this.$http
        .post("course/proctored-exam-certificate", {
          certificateURL: certificateURL,
        })
        .then((resp) => {
          window.open(resp.data.certificate_url, "_blank");
        });
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    changePhone() {
      this.changePhoneNum = true;
    },
    cancelPhone() {
      this.newphonenumber = "";
      this.changePhoneNum = false;
    },
    payClicked(cardData, addressData) {
      let payment = {
        payment_type: "payByEmployee",
        cardholder_street_address: "",
        cardholder_zip: "",
        cardholder_city: "",
        cardholder_state: "",
        transaction_amount: "",
        card_number: cardData.cardNumber,
        card_exp_date: cardData.expire,
      };
      if (addressData.address != "") {
        payment.cardholder_street_address = addressData.address;
        payment.cardholder_zip = addressData.zipcode;
        payment.cardholder_city = addressData.city;
        payment.cardholder_state = addressData.state;
      } else {
        payment.cardholder_street_address = this.address;
        payment.cardholder_zip = this.zipcode;
      }

      payment.transaction_amount = this.discountedCost.toFixed(2);

      this.formData.payment = payment;

      this.$http
        .post("course/pay_by_employee_submission", this.formData)
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            html: `Amount Paid Sucessfully.`,
            icon: "success",
            confirmButton: "btn btn-success",
            confirmButtonText: "Ok",
          }).then((result) => {
            this.fetchData();
          });
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            return Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
            });
          }
        })
        .finally(() => (this.payCourseModel = false , this.showPaymentWindow=false));
    },
    acceptNumber() {
      var x = this.newphonenumber.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.newphonenumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped>
.no-border-card .card-footer {
  border-top: 0;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(1):before {
    content: "Course Name";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(2):before {
    content: "Due Date";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(3):before {
    content: "Lesson Status";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(4):before {
    content: "Estimated Time";
  }
  .empcoursesGrid >>> table.el-table__body td:nth-of-type(5):before {
    content: "Action";
  }
}
</style>
